import { ValueType, OptionsType } from 'react-select';

export type SelectedItem = ValueType<
  OptionsType<{
    value: string;
    label: string;
  }>,
  true | false
>;

export type SelectedItems = Array<SelectedItem>;

export const formCategoryOptions = [
  { value: 'M-Form', label: 'M-Form' },
  { value: 'Permanent Form', label: 'Permanent Form' },
  { value: 'T-Form', label: 'T-Form' },
];

export const formTypeOfBusiness = [
  { value: 'Admitted', label: 'Admitted' },
  { value: 'Non-Admitted', label: 'Non-Admitted' },
];

export const formStatusOptions = [
  { value : 'Active', label: 'Active' },
  { value : 'Inactive', label: 'Inactive' },
];