import { ToastOptions, toast } from 'react-toastify';
import { forIn, includes } from 'lodash';
import moment from 'moment';

import { SelectedItems, SelectedItem } from '../doc-search/config';
import * as api from '../api';
import { Product } from '../components/DocEditContent/partials/SelectedProducts';
import { loadDocEditFile } from '../services';
import { NavigateFunction } from 'react-router-dom';
import { defaultValidationSchema, businessUserValidationSchema, UserRoles } from '../constants';
import Joi from 'joi';
import { updateDocEditMetaData } from '../services/putDocEditMetaData';

export const notify = (
  message: string,
  type: 'info' | 'success' | 'warn' | 'error',
  autoClose: number = 5000,
  options?: ToastOptions
) => {
  toast[type](message, {
    autoClose,
    toastId: message,
    ...options,
  });
};

export const transformSelectedItems = (items?: SelectedItems): Array<string> | undefined => {
  if (!items || !Array.isArray(items)) {
    return undefined;
  }

  return items.map((item: SelectedItem) => (item as any).value) as Array<string>;
};

export const logDocumentAccess = async (document: string, accessType: 'download' | 'view') => {
  api.logDocumentAccess(document, accessType);
};

export const transformFilters = (
  filters?: SearchFilters | FormSetsFilters
): TransformedSearchFilters => {
  let transformedFilters: TransformedSearchFilters = {};

  if (filters) {
    transformedFilters = {
      ...filters,
      impact_on_coverage: (filters as SearchFilters).impact_on_coverage
        ? [...(filters as SearchFilters).impact_on_coverage]
        : undefined,
      form_usage_category: (filters as SearchFilters).form_usage_category
        ? [...(filters as SearchFilters).form_usage_category]
        : undefined,
      form_status: (filters as SearchFilters).form_status
        ? [...(filters as SearchFilters).form_status]
        : undefined,
      form_type: transformSelectedItems(filters.form_type),
      form_source: transformSelectedItems(filters.form_source),
      form_category: transformSelectedItems(filters.form_category),
      carrier: transformSelectedItems(filters.carrier),
      hierarchy: transformSelectedItems(filters.hierarchy),
      line_of_business: transformSelectedItems(filters.line_of_business),
      state_filed: transformSelectedItems(filters.state_filed),
      form_type_of_business: transformSelectedItems(filters.form_type_of_business),
    };
  }

  return transformedFilters;
};

export const removeAzureAuthItemsFromLocalStorage = () => {
  const keysToRemove: Array<string> = [];
  forIn(localStorage, (_value: string, key: string) => {
    if (includes(key, 'login.windows.net')) {
      keysToRemove.push(key);
    }
  });
  keysToRemove.forEach((key: string) => {
    localStorage.removeItem(key);
  });
};

export const clearUser = () => {
  localStorage.removeItem('paper-auth');
  removeAzureAuthItemsFromLocalStorage();
};

export const randomId = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const toFormRule = (index: number, rule: Product) => [
  index,
  {
    businessType: rule.businessType ? 'Admitted' : 'Non-Admitted',
    product: rule.product,
    lineOfBusiness: rule.lineOfBusiness,
    unit: rule.unit,
    subunit: rule.subunit,
  },
  { isActive: rule.isActive, id: rule.id },
];

export const markDuplicateObjects = (
  keys: string[],
  array: Record<string, any>[]
): Record<string, any>[] => {
  const lookup = array.reduce((a: Record<string, number>, e) => {
    const delimeter = keys.reduce((delimeter, curr) => (delimeter = delimeter + e[curr]), '');
    a[delimeter] = ++a[delimeter] || 0;
    return a;
  }, {});

  return array.map((arr) => {
    const delimeter = keys.reduce((delimeter, curr) => (delimeter = delimeter + arr[curr]), '');
    if (lookup[delimeter]) return { ...arr, duplicate: true };
    else return { ...arr, duplicate: false };
  });
};

//TODO: send a flag from backend instead of this?
export const isElasticId = (id: string | undefined) => {
  return id && id?.charAt(8) !== '-' ? true : false;
};

export const redirectToDynamoId = async (
  id: string,
  state: AppState,
  navigate: NavigateFunction
) => {
  const response = await loadDocEditFile(id, state);

  const storedLinkData = localStorage.getItem('link');
  const linkData = storedLinkData ? JSON.parse(storedLinkData) : '';

  if (response && response.file?.data?.id && id !== response.file?.data?.id) {
    //PM user navigates to dynamo form with link data
    if (linkData) await updateDocEditMetaData(response.file.data.id, {}, { link: linkData });
    navigate(`/forms/${response.file.data.id}`);
  } else {
    //PM user navigates to elastic form, pass linkData if it exists through location.state
    navigate(`/forms/${id}`, { state: linkData ? { link: linkData } : null });
  }
};

//returns true if the user is in at least one group
export const userHasRole = (roles: UserRoles[], userRolesArray: UserRoles[]) => {
  if (!userRolesArray) return false;
  return userRolesArray.some((r) => roles.includes(r));
};
export const userHasAccess = userHasRole;

//Disabled if elasticID, PUBLISHED, SAVED, OR in EDIT by someone else
export const disableForm = (viewDoc: ViewDoc, auth: Auth) => {
  return (
    viewDoc.formSource === 'INDEX' ||
    viewDoc.status === 'PUBLISHED' ||
    (viewDoc.status === 'EDIT' && viewDoc.user.email !== auth.user.email) ||
    viewDoc.status === 'SAVED' ||
    (userHasRole([UserRoles.ADMIN], auth.roles as UserRoles[]) && viewDoc.status === 'DRAFT')
  );
};

export const getValidationSchemaByRole = (userRoles: UserRoles[]) => {
  let validationSchema;
  let narrowSchemas = [UserRoles.BUSINESSUSER];
  let scope = {
    narrow: null as any | null,
    default: null as any | null,
  };
  const getSchema = (role: UserRoles) => {
    switch (role) {
      case UserRoles.BUSINESSUSER:
        return businessUserValidationSchema;
      default:
        return defaultValidationSchema;
    }
  };
  scope = userRoles.reduce((acc, role) => {
    validationSchema = getSchema(role);
    if (narrowSchemas.includes(role)) {
      acc.narrow = validationSchema;
    } else {
      acc.default = validationSchema;
    }
    return acc;
  }, scope);

  return Joi.object(scope.narrow || scope.default).options({
    allowUnknown: true,
  });
};

export const getFormattedDate = (date: string) =>
  moment.utc(date).local().format('MM/DD/YYYY hh:mm:ss A');
